<style lang="scss">
.form-header-wrapper {
	.form-header {
		padding: 16px !important;
	}

	.form-header-thank-you-page {
		padding: 56px 48px !important;
	}

	.title-header {
		margin-bottom: 4px !important;
		font-size: 24px !important;
		line-height: 30px !important;
	}

	.sub-title-header {
		font-size: 16px !important;
	}
}
</style>
<template>
	<v-layout v-if="pledgeFormState.pledgeForm" :style="headerStyles" class="form-header-wrapper" align-center justify-start>
		<template v-if="showFullHeader">
			<div class="form-header d-flex pa-6">
				<FormLogo form-type="Pledge"></FormLogo>
				<div class="ml-5 d-flex flex-column justify-center">
					<div :class="computedFontClass" class="title-header" :style="headerStyles">{{ pledgeFormState.pledgeForm.title }}</div>
					<div class="sub-title-header font-weight-thin">{{ pledgeFormState.pledgeForm.primarySubtitle }}</div>
					<div class="text-pre-wrap">{{ pledgeFormState.pledgeForm.introMessage }}</div>
				</div>
			</div>
		</template>
		<template v-else-if="currentPage == 'PledgeThankYou'">
			<v-flex class="text-center form-header-thank-you-page">
				<h4 v-if="!isAdminManaging" :style="headerStyles">Thank you for adding your pledge of {{ pledgeState.pledge.amount | centsToDollars }}</h4>
				<h4 v-if="isAdminManaging" :style="headerStyles">{{ pledgeFormState.pledgeAccount.label }}'s pledge of {{ pledgeState.pledge.amount | centsToDollars }} has been added.</h4>
				<div class="subheading mt-4">Frequency: {{ pledgeState.pledge.frequency | enumText('PledgeFrequency') | lowerCase }} from {{ pledgeState.pledge.period }}</div>
				<div class="subheading mt-4">Total Pledge: {{ pledgeState.pledge.totalAmount | dollars }}</div>
				<div v-if="pledgeFormState.pledgeForm.receiptMessage" class="subheading mt-4 text-pre-wrap">{{ pledgeFormState.pledgeForm.receiptMessage }}</div>
				<div v-if="!isAdminManaging" class="mt-4">You will receive an email confirmation for this pledge.</div>
				<ActionButton v-if="inIframe || isEngagementAndIOS" color="default" :font="pledgeFormState.pledgeForm.font" class="mt-4" @click="closeDonorForm">Finish</ActionButton>
			</v-flex>
		</template>
		<div v-else class="pa-3"></div>
	</v-layout>
</template>

<script>
import ActionButton from '@/components/giving/sharedForms/ActionButton.vue';
import FormLogo from '@/components/giving/sharedForms/FormLogo';

export default {
	components: {
		ActionButton,
		FormLogo
	},
	data() {
		return {
			pledgeFormState: _REALM.STORE.pledgeFormStore.state,
			pledgeState: _REALM.STORE.pledgeStore.state,
			siteState: _REALM.STORE.siteStore.state,
			transactionState: _REALM.STORE.transactionStore.state,
			transactionStore: _REALM.STORE.transactionStore,
			imageTypes: _REALM.STORE.mediaStore.ImageTypes
		};
	},
	computed: {
		isEngagementAndIOS() {
			return this.isEngagement && _REALM.TOOLS.browser().isIOS;
		},
		isEngagementAndAndroid() {
			return this.isEngagement && _REALM.TOOLS.browser().isAndroid;
		},
		isEngagement() {
			return _REALM.VM.$route.query.isEngagement && _REALM.VM.$route.query.isEngagement.toLowerCase() === 'true';
		},
		isAdminManaging() {
			return _REALM.STORE.pledgeFormStore.isAdminManaging();
		},
		inIframe() {
			return _REALM.TOOLS.inIframe();
		},
		headerStyles() {
			return {
				backgroundColor: this.pledgeFormState.pledgeForm.headerBackgroundColor,
				color: _REALM.TOOLS.getContrastColor(this.pledgeFormState.pledgeForm.headerBackgroundColor)
			};
		},
		computedFontClass() {
			return this.pledgeFormState.pledgeForm.font + '-font-family';
		},
		showFullHeader() {
			return ['pledge-form', 'KnownGiver', 'LandingPage', 'PledgeIntro'].includes(this.currentPage);
		},
		currentPage() {
			if (_REALM.STORE.layoutStore.isPledgeForm()) {
				return this.pledgeFormState.currentDonorPage;
			}

			return _REALM.VM.$route.name;
		}
	},
	methods: {
		closeDonorForm() {
			if (this.isEngagementAndIOS) {
				window.location = _REALM.CONFIG.EngagementRoot + '/giving/history';
			} else {
				_REALM.EVENT.emit('close-donor-form', this.pledgeState.pledge);
			}
		}
	}
};
</script>
