<template>
	<v-layout>
		<v-flex>
			<component :is="pledgeFormState.currentDonorPage" :key="realmVm.$route.fullPath"></component>
		</v-flex>
	</v-layout>
</template>
<script>
import PledgePicker from '@/components/giving/donorPledgeForm/PledgePicker.vue';
import PledgeIntro from '@/components/giving/donorPledgeForm/PledgeIntro.vue';
import KnownGiver from '@/components/giving/sharedForms/KnownGiver.vue';
import PledgeThankYou from '@/components/giving/donorPledgeForm/PledgeThankYou.vue';
import LandingPage from '@/components/giving/donorForm/LandingPage.vue';

export default {
	components: {
		PledgeIntro,
		KnownGiver,
		PledgeThankYou,
		LandingPage,
		PledgePicker
	},
	data() {
		return {
			pledgeFormState: _REALM.STORE.pledgeFormStore.state,
			givingSettings: _REALM.STORE.givingStore.state.settings,
			realmVm: _REALM.VM
		};
	},
	watch: {
		'pledgeFormState.currentDonorPage'() {
			//Scroll to top of page on each component change. This is primarily needed for mobile
			this.$vuetify.goTo('body');
		}
	}
};
</script>
