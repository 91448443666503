<template>
	<div>
		<r-header v-if="hasActions">Before you go...</r-header>
		<v-checkbox v-if="canCreateAccount" v-model="createAccountChecked" class="mt-0 mb-3" label="Create an account for future use." persistent-hint hint="You can see a record of all gifts, manage scheduled gifts, and pledge progress."></v-checkbox>
		<RegisterForm v-if="createAccountChecked && canCreateAccount" type="giverSignup"></RegisterForm>
		<v-checkbox v-if="canCreateRecurrence" v-model="makeRecurringChecked" class="mt-0" label="Add an online gift to fulfill this pledge." @click="showGivingFormEmbed"></v-checkbox>
	</div>
</template>
<script>
import RegisterForm from '@/components/giving/account/RegisterForm.vue';
import { formatCentsToDollars } from '@/helpers/formatters';
export default {
	components: {
		RegisterForm
	},
	data() {
		return {
			pledgeState: _REALM.STORE.pledgeStore.state,
			pledgeFormState: _REALM.STORE.pledgeFormStore.state,
			createAccountChecked: false,
			makeRecurringChecked: false,
			layoutState: _REALM.STORE.layoutStore.state,
			accountStore: _REALM.STORE.accountStore,
			accountState: _REALM.STORE.accountStore.state,
			transactionState: _REALM.STORE.transactionStore.state,
			site: _REALM.STORE.siteStore.state.site,
			onlineFunds: []
		};
	},
	computed: {
		canCreateAccount() {
			return (
				this.site.invitationModelSite == 'Open' &&
				!_REALM.STORE.accountStore.isLoggedIn() &&
				!(this.pledgeState.pledge.donorHasAccount || this.transactionState.transaction.donorHasAccount) &&
				!(this.pledgeState.pledge.donorSentAccountInvite || this.transactionState.transaction.donorSentAccountInvite)
			);
		},
		canCreateRecurrence() {
			return !this.pledgeState.pledge.hasRecurringGift && this.pledgeFormState.pledgeForm.askForOnlineGifts && this.onlineFunds.includes(this.pledgeFormState.pledgeForm.fundId) && (this.transactionState.transaction.recurrenceId === null || this.transactionState.transaction.recurrenceId === '');
		},
		isEngagementAndIOS() {
			return _REALM.VM.$route.query.isEngagement && _REALM.VM.$route.query.isEngagement.toLowerCase() === 'true' && _REALM.TOOLS.browser().isIOS ? true : false;
		},
		hasActions() {
			return this.canCreateAccount || this.canCreateRecurrence;
		}
	},
	created() {
		this.setupAccountSignup();
		this.getOnlineFunds();
		this.pledgeFormState.hidePledgeFormContent = !this.hasActions;
	},
	methods: {
		getOnlineFunds() {
			var self = this;
			_REALM.STORE.fundStore.listOnline({ filterCampusFunds: false }).then(function(onlineFunds) {
				self.onlineFunds = onlineFunds.map(function(obj) {
					return obj.fundId;
				});
				self.pledgeFormState.hidePledgeFormContent = !self.hasActions;
			});
		},
		setupAccountSignup() {
			_REALM.EVENT.on(this, 'account-registered', this.accountRegistered);
			this.accountState.newAccount = {
				name: _REALM.STORE.pledgeFormStore.state.pledgeAccount.label,
				email: _REALM.STORE.pledgeFormStore.state.pledgeAccount.primaryEmail
			};
		},
		accountRegistered() {
			_REALM.TOAST.success(`Your account has been created. You will receive an email shortly asking you to confirm your email address.`);
			_REALM.VM.$set(_REALM.STORE.pledgeStore.state.pledge, 'donorSentAccountInvite', true);
		},
		closeDonorForm(pledge) {
			if (this.isEngagementAndIOS) {
				window.location = _REALM.CONFIG.EngagementRoot + '/giving/history';
			} else {
				_REALM.EVENT.emit('close-donor-form', pledge);
			}
		},
		showGivingFormEmbed() {
			var self = this;
			var afterCloseCallback = function(transaction) {
				if (transaction) {
					self.closeDonorForm(transaction);
				} else {
					self.makeRecurringChecked = false;
				}
			};

			var options = {
				beforeOpenCallback: _REALM.LOADER.show(),
				afterOpenCallback: _REALM.LOADER.hide(),
				afterCloseCallback: afterCloseCallback,
				individualId: _REALM.STORE.pledgeStore.state.pledge.individualId,
				mintCustomerId: _REALM.STORE.pledgeStore.state.pledge.mintCustomerId,
				fundId: _REALM.STORE.pledgeFormStore.state.pledgeForm.fundId,
				amount: formatCentsToDollars(_REALM.STORE.pledgeStore.state.pledge.amount),
				email: _REALM.STORE.pledgeFormStore.state.pledgeAccount.primaryEmail,
				donorSentAccountInvite: self.pledgeState.pledge.donorSentAccountInvite,
				integrationType: _REALM.STORE.pledgeFormStore.isPledgeFormEmbed() ? 'Embed' : _REALM.TOOLS.inIframe() ? 'REALM' : 'WEBSITE',
				isEngagement: _REALM.VM.$route.query.isEngagement && _REALM.VM.$route.query.isEngagement.toLowerCase() === 'true',
				pledgeData: {
					frequency: _REALM.STORE.pledgeStore.state.pledge.frequency,
					beginDate: _REALM.STORE.pledgeStore.state.pledge.startDate,
					endDate: _REALM.STORE.pledgeStore.state.pledge.stopDate,
					isNewProfile:
						_REALM.STORE.pledgeStore.state.pledge.isNewProfile &&
						!_REALM.STORE.accountStore.isLoggedIn() &&
						!(this.pledgeState.pledge.donorHasAccount || this.transactionState.transaction.donorHasAccount) &&
						!(this.pledgeState.pledge.donorSentAccountInvite || this.transactionState.transaction.donorSentAccountInvite)
				}
			};

			_REALM.EMBED.show(options);
		}
	}
};
</script>
