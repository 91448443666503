<template>
	<div>
		<div v-if="showGuestMessage">
			<h5>We do not recognize this email.&nbsp;Call the church to create an account.</h5>
		</div>
		<div v-else>
			<r-form v-if="pledgeFormLoaded" ref="pledgeIntroForm" :submitMethod="nextPage" class="pledge-intro-wrapper">
				<DonorTitle :font="pledgeFormState.pledgeForm.font">{{ computedHeader }}</DonorTitle>
				<v-flex xs12 sm6>
					<r-text-input v-model="pledgeState.pledge.amount" autofocus required type="atm" placeholder="0.00" label="Amount" x-large :prefixColor="pledgeFormState.pledgeForm.accentColor"></r-text-input>
				</v-flex>
				<PledgeFrequency></PledgeFrequency>
				<r-text-input v-if="!pledgeFormState.pledgeAccount.hasEmail && !isPreviewPledgeFormMode" v-model="pledgeFormState.pledgeAccount.label" outlined type="text" label="Name" autocomplete="name" required maxlength="150"></r-text-input>
				<r-text-input v-if="!pledgeFormState.pledgeAccount.hasEmail && !isPreviewPledgeFormMode" v-model="pledgeFormState.pledgeAccount.primaryEmail" outlined type="email" label="Email address" autocomplete="email" required maxlength="255"></r-text-input>
				<AmountSummary form-type="Pledge" :recurrence-count="recurrenceCount" :total-loading="fetchingRecurrenceCount" />
				<v-layout>
					<v-flex text-right>
						<r-btn v-if="inIframe" color="default" class="mr-2" @click="closeDonorForm">Cancel</r-btn>
						<ActionButton type="submit" :color="pledgeFormState.pledgeForm.accentColor" :font="pledgeFormState.pledgeForm.font">Save Pledge</ActionButton>
					</v-flex>
				</v-layout>
			</r-form>
		</div>
	</div>
</template>
<script>
import ActionButton from '@/components/giving/sharedForms/ActionButton.vue';
import PledgeFrequency from '@/components/giving/donorPledgeForm/PledgeFrequency.vue';
import DonorTitle from '@/components/giving/sharedForms/DonorTitle.vue';
import AmountSummary from '@/components/giving/sharedForms/AmountSummary';
import variables from '@/scss/variables.scss';
import moment from 'moment';
import debounce from 'lodash/debounce';

export default {
	components: {
		PledgeFrequency,
		ActionButton,
		DonorTitle,
		AmountSummary
	},
	data() {
		return {
			pledgeFormStore: _REALM.STORE.pledgeFormStore,
			pledgeFormLoaded: false,
			showGuestMessage: false,
			pledgeFormState: _REALM.STORE.pledgeFormStore.state,
			siteState: _REALM.STORE.siteStore.state,
			pledgeState: _REALM.STORE.pledgeStore.state,
			accountState: _REALM.STORE.accountStore.state,
			accountStore: _REALM.STORE.accountStore,
			debouncedRecurrenceCount: null,
			recurrenceCount: 0,
			fetchingRecurrenceCount: false
		};
	},
	computed: {
		inIframe() {
			return _REALM.TOOLS.inIframe();
		},
		isPledgeFormView() {
			return _REALM.VM.$route.params.pledgeFormUrl !== undefined;
		},
		computedHeader() {
			return !_REALM.STORE.pledgeFormStore.isPreviewPledgeFormMode() && _REALM.STORE.pledgeFormStore.isAdminManaging() ? 'Enter Pledge for ' + this.pledgeFormState.pledgeAccount.label : 'How much would you like to pledge?';
		},
		isPreviewPledgeFormMode() {
			return _REALM.STORE.pledgeFormStore.isPreviewPledgeFormMode();
		}
	},
	watch: {
		'pledgeState.pledge.frequency'() {
			this.fetchingRecurrenceCount = true;
			this.debouncedRecurrenceCount();
		},
		'pledgeState.pledge.startDate'(newVal, oldVal) {
			if (newVal && oldVal && newVal.toISOString() === oldVal.toISOString()) {
				return;
			}
			this.fetchingRecurrenceCount = true;
			this.debouncedRecurrenceCount();
		},
		'pledgeState.pledge.stopDate'(newVal, oldVal) {
			if (newVal && oldVal && newVal.toISOString() === oldVal.toISOString()) {
				return;
			}
			this.fetchingRecurrenceCount = true;
			this.debouncedRecurrenceCount();
		}
	},
	created() {
		this.getPledgeForm();
		this.debouncedRecurrenceCount = debounce(this.getRecurrenceCount, 500);
	},
	methods: {
		closeDonorForm() {
			_REALM.EVENT.emit('close-donor-form');
		},
		setPledgeFormLoadedValues(campaign) {
			if (!this.isPreviewPledgeFormMode) {
				this.$vuetify.theme.themes.light.primary = this.pledgeFormState.pledgeForm.useDarkBodyScheme ? variables['color-white'] : variables['color-black'];
				this.$vuetify.theme.themes.light.secondary = this.pledgeFormState.pledgeForm.accentColor;
				this.$vuetify.theme.themes.light.accent = this.pledgeFormState.pledgeForm.accentColor;
				this.$vuetify.theme.themes.light.info = this.pledgeFormState.pledgeForm.accentColor;
				this.$vuetify.theme.themes.light.error = this.pledgeFormState.pledgeForm.useDarkBodyScheme ? variables['color-error-dark-theme'] : variables['color-error'];
				this.$vuetify.theme.themes.dark.primary = this.pledgeFormState.pledgeForm.useDarkBodyScheme ? variables['color-white'] : variables['color-black'];
				this.$vuetify.theme.themes.dark.secondary = this.pledgeFormState.pledgeForm.accentColor;
				this.$vuetify.theme.themes.dark.accent = this.pledgeFormState.pledgeForm.accentColor;
				this.$vuetify.theme.themes.dark.info = this.pledgeFormState.pledgeForm.accentColor;
				this.$vuetify.theme.themes.dark.error = this.pledgeFormState.pledgeForm.useDarkBodyScheme ? variables['color-error-dark-theme'] : variables['color-error'];
				_REALM.STORE.layoutStore.setDarkTheme(this.pledgeFormState.pledgeForm.useDarkBodyScheme);
			}

			var campaignStartDate = campaign ? moment(campaign.startDate) : this.pledgeFormState.pledgeForm.campaignStartDate;
			this.pledgeState.pledge.startDate = campaignStartDate.isAfter(moment(), 'day') ? campaignStartDate : moment();
			this.pledgeState.pledge.stopDate = campaign ? moment(campaign.stopDate) : this.pledgeFormState.pledgeForm.campaignStopDate;
			this.pledgeFormLoaded = true;
		},
		getRecurrenceCount() {
			var self = this;
			_REALM.STORE.pledgeStore
				.getRecurrenceCount({ frequency: this.pledgeState.pledge.frequency, startDate: this.pledgeState.pledge.startDate.format('MM/DD/YYYY'), stopDate: this.pledgeState.pledge.stopDate.format('MM/DD/YYYY') })
				.then(data => {
					self.recurrenceCount = data.count;
				})
				.finally(() => {
					self.fetchingRecurrenceCount = false;
				});
		},
		getPledgeForm() {
			var self = this;
			if (self.isPreviewPledgeFormMode) {
				self.setPledgeFormLoadedValues();
				return;
			}

			var campaignId = _REALM.VM.$route.query.campaignId ? _REALM.VM.$route.query.campaignId : _REALM.VM.$route.params.campaignId;
			if (_REALM.VM.$route.params.pledgeFormUrl) {
				_REALM.STORE.pledgeFormStore
					.getPledgeFormByUrl(_REALM.VM.$route.params.pledgeFormUrl)
					.then(function() {
						self.setPledgeFormLoadedValues();
					})
					.catch(function() {
						self.redirectPledgeFormPicker();
					});
			} else if (campaignId) {
				_REALM.STORE.campaignStore
					.getCampaign(campaignId)
					.then(function(campaign) {
						_REALM.STORE.pledgeFormStore.setPledgeFormDefaults(campaign);
						self.setPledgeFormLoadedValues(campaign);
					})
					.catch(function() {
						self.redirectPledgeFormPicker();
					});
			} else {
				self.redirectPledgeFormPicker();
			}
		},
		redirectPledgeFormPicker() {
			this.pledgeFormState.currentDonorPage = 'PledgePicker';
			this.pledgeFormLoaded = true;
		},
		redirectNextPage(pageName) {
			this.pledgeFormState.currentDonorPage = pageName;
		},
		nextPage() {
			var self = this;
			if (self.$refs.pledgeIntroForm.validate() && this.validateDates()) {
				if (_REALM.STORE.accountStore.isLoggedIn()) {
					self.savePledge();
				} else {
					_REALM.LOADER.show();
					_REALM.STORE.accountStore.IndividualIdByEmail(self.pledgeFormState.pledgeAccount.primaryEmail).then(function(resp) {
						_REALM.LOADER.hide();

						let individualId = null;
						self.pledgeState.pledge.donorHasAccount = false;
						self.pledgeState.pledge.donorHasProfile = false;

						if (resp && resp.individualIdByAccountEmail !== null) {
							self.pledgeState.pledge.donorHasAccount = true;
							individualId = resp.individualIdByAccountEmail;
						}

						if (self.pledgeState.pledge.donorHasAccount || (resp && resp.individualIdByPrimaryEmail !== null)) {
							self.pledgeState.pledge.donorHasProfile = true;
							individualId = self.pledgeState.pledge.donorHasAccount ? individualId : resp.individualIdByPrimaryEmail;
						}

						_REALM.STORE.pledgeFormStore.state.pledgeAccount.individualId = individualId;
						_REALM.STORE.transactionStore.state.transaction.individualId = individualId;

						if (self.pledgeState.pledge.donorHasProfile || self.pledgeState.pledge.donorHasAccount || self.pledgeFormState.pledgeForm.unauthenticatedPledgingEnabled) {
							self.savePledge();
						} else {
							self.showGuestMessage = true;
						}
					});
				}
			}
		},
		savePledge() {
			var self = this;
			_REALM.LOADER.show();
			var args = _REALM.STORE.pledgeStore.buildAddPledgeArgs();
			return _REALM.STORE.pledgeStore.addPledge(args).then(function() {
				_REALM.LOADER.hide();
				self.pledgeFormState.currentDonorPage = 'PledgeThankYou';
			});
		},
		validateDates() {
			if (this.pledgeState.pledge.startDate.isBefore(this.pledgeFormState.pledgeForm.campaignStartDate, 'day')) {
				_REALM.TOAST.error(`The start date of the pledge cannot be earlier than ${this.pledgeFormState.pledgeForm.campaignStartDate.format('ll')}.`);
				return false;
			}

			if (this.pledgeState.pledge.stopDate.isAfter(this.pledgeFormState.pledgeForm.campaignStopDate, 'day')) {
				_REALM.TOAST.error(`The stop date of the pledge cannot be later than ${this.pledgeFormState.pledgeForm.campaignStopDate.format('ll')}.`);
				return false;
			}

			return true;
		}
	}
};
</script>
