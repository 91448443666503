<style lang="scss">
.pledge-picker-wrapper {
	.title-header {
		margin-bottom: 4px !important;
		font-size: 24px !important;
		line-height: 30px !important;
	}
}
</style>
<template>
	<div class="pledge-picker-wrapper">
		<h5 v-if="pledgeFormsLoaded && pledgeForms.length > 0">Which campaign would you like to pledge to?</h5>
		<h5 v-else-if="pledgeFormsLoaded && pledgeForms.length === 0">There are no campaigns available.</h5>
		<v-layout v-if="pledgeForms.length > 0" row wrap class="mt-3">
			<v-flex v-for="(pledgeForm, index) in pledgeForms" :key="index" md6 class="cursor-pointer" @click="setPledgeForm(pledgeForm)">
				<r-card class="mb-1 rounded" :style="pickerStyles(pledgeForm)" outlined hover rounded>
					<div class="title-header text-center" :style="pickerStyles(pledgeForm)">
						{{ pledgeForm.title }}
					</div>
					<div class="sub-title-header font-weight-thin text-center" :style="pickerStyles(pledgeForm)">{{ pledgeForm.campaignStartDate | date }} - {{ pledgeForm.campaignStopDate | date }}</div>
				</r-card>
			</v-flex>
		</v-layout>
		<v-layout>
			<v-spacer></v-spacer>
			<v-flex text-right>
				<r-btn v-if="inIframe" color="default" class="mr-2" @click="closeDonorForm">Cancel</r-btn>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
import cssVariables from '@/scss/variables.scss';
export default {
	components: {},
	data() {
		return {
			pledgeFormStore: _REALM.STORE.pledgeFormStore,
			pledgeFormState: _REALM.STORE.pledgeFormStore.state,
			siteState: _REALM.STORE.siteStore.state,
			pledgeState: _REALM.STORE.pledgeStore.state,
			accountState: _REALM.STORE.accountStore.state,
			accountStore: _REALM.STORE.accountStore,
			pledgeForms: [],
			pledgeFormsLoaded: false
		};
	},
	computed: {
		inIframe() {
			return _REALM.TOOLS.inIframe();
		}
	},
	created() {
		this.listPledgeForms();
		_REALM.STORE.pledgeFormStore.setPledgeFormDefaults();
		this.pledgeFormState.pledgeForm.pledgeFormId = null;
		this.pledgeFormState.pledgeForm.headerBackgroundColor = cssVariables['color-primary'];
	},
	methods: {
		closeDonorForm() {
			_REALM.EVENT.emit('close-donor-form');
		},
		setPledgeForm(pledgeForm) {
			var self = this;

			var query = {
				campaignId: pledgeForm.campaignId
			};

			if (_REALM.VM.$route.query.isEngagement) {
				query.isEngagement = _REALM.VM.$route.query.isEngagement;
			}

			_REALM.VM.$router.push(
				{
					name: 'donor-pledge-form',
					params: {
						pledgeFormUrl: pledgeForm ? pledgeForm.urlPath : null
					},
					query: query
				},
				function() {
					self.pledgeFormState.currentDonorPage = 'PledgeIntro';
				}
			);
		},
		listPledgeForms() {
			var self = this;
			var individualId = _REALM.STORE.accountStore.isLoggedIn() && self.pledgeFormState.pledgeAccount && self.pledgeFormState.pledgeAccount.individualId ? self.pledgeFormState.pledgeAccount.individualId : null;
			return _REALM.STORE.pledgeFormStore.listPledgeForms(individualId).then(function(pledgeForms) {
				if (pledgeForms && pledgeForms.length === 1) {
					self.setPledgeForm(pledgeForms[0]);
				} else {
					self.pledgeForms = pledgeForms;
					self.pledgeFormsLoaded = true;
				}
			});
		},
		pickerStyles(pledgeForm) {
			var backgroundColor = pledgeForm && pledgeForm.headerBackgroundColor ? pledgeForm.headerBackgroundColor : cssVariables['color-primary'];
			return {
				backgroundColor: backgroundColor,
				color: _REALM.TOOLS.getContrastColor(backgroundColor)
			};
		}
	}
};
</script>
