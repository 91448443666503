<style lang="scss">
.frequency-wrapper {
	.small-btn button {
		font-size: 11px;
	}
}
</style>

<template>
	<v-layout wrap class="frequency-wrapper">
		<v-flex class="pb-8 pt-0" xs12>
			<v-btn-toggle v-model="multipleTimeSelected" mandatory large class="toggle-btn" :class="{ 'small-btn': $vuetify.breakpoint.xsOnly }">
				<v-btn :value="false">As Can</v-btn>
				<v-btn :value="true">Set a Schedule</v-btn>
			</v-btn-toggle>
		</v-flex>
		<v-flex v-if="multipleTimeSelected" xs12 class="py-0">
			<v-btn-toggle v-if="$vuetify.breakpoint.mdAndUp" v-model="pledgeState.pledge.frequency" class="mb-8" :class="{ 'small-btn': $vuetify.breakpoint.xsOnly || isPledgePreviewPage }" mandatory large>
				<v-btn v-for="pledgeFrequency in pledgeFrequencies" :key="pledgeFrequency.Value" height="48" :value="pledgeFrequency.Name" :large="$vuetify.breakpoint.smAndUp && !isPledgePreviewPage">{{ pledgeFrequency.Text | digitWordsToNumbers }}</v-btn>
			</v-btn-toggle>
			<r-select-list v-if="!$vuetify.breakpoint.mdAndUp" v-model="pledgeState.pledge.frequency" outlined :options="pledgeFrequenciesMobileOptions" label="Frequency"></r-select-list>
		</v-flex>
		<v-flex xs6 class="py-0">
			<r-date-picker v-if="pledgeState.pledge.startDate" v-model="pledgeState.pledge.startDate" label="Start Date" required outlined></r-date-picker>
		</v-flex>
		<v-flex xs6 class="py-0">
			<r-date-picker v-if="pledgeState.pledge.stopDate" v-model="pledgeState.pledge.stopDate" label="End Date" required outlined></r-date-picker>
		</v-flex>
	</v-layout>
</template>
<script>
export default {
	data() {
		return {
			pledgeFormState: _REALM.STORE.pledgeFormStore.state,
			pledgeState: _REALM.STORE.pledgeStore.state,
			multipleTimeSelected: false,
			isMobile: _REALM.TOOLS.browser().mobile
		};
	},
	computed: {
		pledgeFrequencies() {
			var pledgeFrequency = Object.assign({}, STRATUS.EnumImports.PledgeFrequency);
			delete pledgeFrequency.AsCan;
			return pledgeFrequency;
		},
		pledgeFrequenciesMobileOptions() {
			return _REALM.TOOLS.buildSelectList(this.pledgeFrequencies, 'Name', 'Text');
		},
		isPledgePreviewPage() {
			return _REALM.VM.$route.name === 'pledge-form';
		}
	},
	watch: {
		multipleTimeSelected(val) {
			if (val) {
				this.pledgeState.pledge.frequency = this.pledgeFormState.pledgeForm.defaultFrequency;
			} else {
				this.pledgeState.pledge.frequency = STRATUS.EnumImports.PledgeFrequency.AsCan.Name;
			}
		}
	},
	mounted() {
		this.multipleTimeSelected = this.pledgeFormState.pledgeForm.defaultFrequency !== 'AsCan';
	}
};
</script>
